import React from "react";
import Modal from "react-bootstrap/Modal";
import { Card, Image } from "semantic-ui-react";
import "./g-p-modal.css";

const MyVerticallyCenteredModalDoctorHelper = (props) => {
  return (
    <Modal
      {...props}
      key={props.el.id}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Заместващи лекари на <br></br>
          Д-р {props.el.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.el.helper.map((doctor, index) => {
          return (
            <Card key={index}>
              <Card.Content>
                <Image floated="right" size="mini" src={doctor.helperPhoto} />
                <Card.Header>Д-р {doctor.helperName}</Card.Header>
                {doctor.helperPhone ? (
                  <>
                    📞{" "}
                    <a
                      className="phone-number-doctor"
                      href={"tel:" + doctor.helperPhone}
                    >
                      {doctor.helperPhone}
                    </a>
                  </>
                ) : null}
              </Card.Content>
            </Card>
          );
        })}
      </Modal.Body>
      <Modal.Footer>
        <button className="red-close-button" onClick={props.onHide}>
          Затвори
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default MyVerticallyCenteredModalDoctorHelper;
