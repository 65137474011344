const TableSearchLogic = (LaboratoryNZOKData, search, setData) => {
  const searchedItems = LaboratoryNZOKData.reduce((acc, el) => {
    let hasSubType = false;
    let hasText = false;

    el.info.forEach((info) => {
      if (
        info.subType.toLocaleLowerCase().includes(search.toLocaleLowerCase())
      ) {
        hasSubType = true;
      }
      info.text.forEach((content) => {
        if (content.toLocaleLowerCase().includes(search.toLocaleLowerCase())) {
          hasText = true;
        }
      });
    });

    if (el.type.toLocaleLowerCase().includes(search.toLocaleLowerCase())) {
      acc.push(el);
    } else if (hasSubType === true) {
      const findSubType = el.info.filter((element) =>
        element.subType.toLocaleLowerCase().includes(search.toLocaleLowerCase())
      );

      const info = [...findSubType];
      acc.push({
        id: el.id,
        type: el.type,
        info: info,
      });
    } else if (hasText) {
      const element = {
        id: el.id,
        type: el.type,
        info: [],
      };
      el.info.forEach((info) => {
        const text = info.text.filter((content) =>
          content.toLocaleLowerCase().includes(search.toLocaleLowerCase())
        );

        if (text.length > 0) {
          element.info.push({
            subType: info.subType,
            text: text,
          });
        }
      });
      acc.push(element);
    }
    return acc;
  }, []);

  setData(searchedItems);
};

export default TableSearchLogic;
