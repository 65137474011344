import React from "react";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import "./g-p-modal.css";

const MyVerticallyCenteredModal = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      key={"modal_" + props.name + "_" + props.id}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          График Д-р {props.el.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table responsive="xl" striped bordered hover>
          <thead>
            <tr>
              <th>Понеделник</th>
              <th>Вторник</th>
              <th>Сряда</th>
              <th>Четвъртък</th>
              <th>Петък</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              {props.el.hoursPerDay.map((hours, index) => {
                return (
                  <td key={"hour_per_day_" + index}>
                    {hours}
                    {hours.length > 3 ? " ч." : null}
                  </td>
                );
              })}
            </tr>
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <button className="red-close-button" onClick={props.onHide}>
          Затвори
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default MyVerticallyCenteredModal;
