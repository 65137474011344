const LaboratoryData = [
  {
    id: 1,
    type: "Хематологични и цитологични изследвания ",
    info: [
      {
        subType: "",
        text: [
          "Кръвна картина – поне осем от посочените показатели или повече : хемоглобин еритроцити , левкоцити , хематокрит , тромбоцити , MCV , MCH , MCHC",
          "Диференциално броене на левкоцити — визуално микроскопско или автоматично апаратно изследване",
          "Морфология на еритроцити — визуално микроскопско изследване",
          "Скорост на утаяване на еритроцитите",
        ],
      },
    ],
  },
  {
    id: 2,
    type: "Кръвосъсирване и фибринолиза ",
    info: [
      {
        subType: "",
        text: [
          "Време на кървене",
          "Протромбиново време",
          "Активирано парциално тромбопластиново време ( АРТТ )",
          "Фибриноген",
        ],
      },
    ],
  },
  {
    id: 3,
    type: "Изследване на урина – основна програма",
    info: [
      {
        subType: "",
        text: [
          "Химично изследване на урина ( pH , белтък , билирубин , уробилиноген , глюкоза ,  кетони , относително тегло , нитрити , левкоцити , кръв )",
          "Седимент - ориентировъчно изследване",
        ],
      },
    ],
  },
  {
    id: 4,
    type: "Изследване на изпражнения",
    info: [
      {
        subType: "",
        text: ["Окултни кръвоизливи"],
      },
    ],
  },
  {
    id: 5,
    type: "Клинично - химични изследвания",
    info: [
      {
        subType: "Субстрати",
        text: [
          "Глюкоза",
          "Кръвно - захарен профил",
          "Орален глюкозотолерантен тест",
          "Креатинин",
          "Уреа",
          "Билирубин — общ",
          "Билирубин — директен",
          "Общ белтък",
          "Албумин",
          "CRP",
          "Холестерол",
          "LDL - холестерол",
          "HDL - холестерол",
          "Триглицериди",
          "Гликиран хемоглобин",
          "Пикочна киселина",
          "Липиден профил ( включващ общ холестерол , HDL - холестерол , LDL - холестерол)",
        ],
      },
      {
        subType: "Ензими",
        text: [
          "АСАТ",
          "АЛАТ",
          "Креатинкиназа ( KK )",
          "ГГТ",
          "Алкална фосфатаза ( АФ )",
          "Алфа-амилаза",
          "Липаза",
        ],
      },
      {
        subType: "Електролити и олигоелементи",
        text: ["Натрий и Калий", "Калций", "Фосфати", "Желязо"],
      },
    ],
  },
  {
    id: 6,
    type: "Високоспециализирани изследвания",
    info: [
      {
        subType: "Хормони",
        text: [
          "T4",
          "TSH",
          "LH",
          "FSH",
          "Prolactin",
          "Estradiol",
          "Testosteron",
          "Progesteron",
          "Антитела срещу Тиреоидната пероксидаза / Anti - TPO /",
        ],
      },
      {
        subType: "Туморни маркери",
        text: [
          "PSA",
          "fPSA",
          "fPSA / tPSA",
          "СА - 15-3",
          "СА - 19-9",
          "СА - 125",
          "СЕА",
          "Алфа - фетопротеин",
          "Бета - хорионгонадотропин",
        ],
      },
      {
        subType: "Изследване на урина",
        text: ["Микроалбуминурия"],
      },
      {
        subType: "Други",
        text: [
          "AST",
          "RF",
          "Определяне на повърхностен антиген на хепатит B (HBsAg) с бърз тест",
          "Определяне на антитела срещу хепатит C (anti-HCV) с бърз тест",
          "D-Dmer",
        ],
      },
    ],
  },
];

export default LaboratoryData;
