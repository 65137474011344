import Man from "../images/GP/mandoctor.PNG";
import Woman from "../images/GP/womandoctor.PNG";

const gpData = [
  {
    id: 1,
    name: "Валентин Лилов",
    phone: "+359889616137",
    otherSpecialist: [
      {
        id: 201,
        helperName: "Иванка Младенова",
        otherSpec: "Вътрешни болести",
      },
    ],
    helper: [
      {
        id: 101,
        helperName: "Веселка Велкова",
        helperPhone: "+359888213285",
        helperPhoto: Woman,
      },
    ],
    otherSpec: null,
    photo: Man,
    hoursPerDay: [
      "08:30 - 13:00",
      "08:30 - 13:00",
      "08:30 - 13:00",
      "08:30 - 13:00",
      "08:30 - 13:00",
    ],
    workingType: [
      "Амбулаторни прегледи",
      "Домашни прегледи",
      "Промотивна и профилактична дейност",
      "Дейност по детско здравеопазване",
      "Дейност по майчинско здравеопазване",
    ],
  },
  {
    id: 2,
    name: "Веселка Велкова",
    otherSpecialist: [],
    phone: "+359888213285",
    helper: [
      {
        id: 102,
        helperName: "Валентин Лилов",
        helperPhone: "+359889616137",
        helperPhoto: Man,
      },
    ],
    otherSpec: "белодробни болести",
    photo: Woman,
    hoursPerDay: [
      "13:00 - 18:00",
      "13:00 - 18:00",
      "13:00 - 18:00",
      "08:30 - 13:00",
      "08:30 - 13:00",
    ],
    workingType: [
      "Амбулаторни прегледи",
      "Домашни прегледи",
      "Промотивна и профилактична дейност",
      "Дейност по детско здравеопазване",
      "Дейност по майчинско здравеопазване",
    ],
  },
  {
    id: 3,
    name: "Цветан Първанов",
    otherSpecialist: [],
    phone: "+359877185393",
    helper: [
      {
        id: 104,
        helperName: "Ирена Илиева",
        helperPhone: "+359887236000",
        helperPhoto: Woman,
      },
    ],
    otherSpec: "вътрешни болести",

    photo: Man,
    hoursPerDay: [
      "08:00 - 13:00",
      " ",
      "08:00 - 14:00",
      "12:00 - 18:00",
      "08:00 - 13:00",
    ],
    workingType: [
      "Амбулаторни прегледи",
      "Домашни прегледи",
      "Промотивна и профилактична дейност",
      "Дейност по детско здравеопазване",
      "Дейност по майчинско здравеопазване",
    ],
    more: ["Извършва прегледи в с. Горно Церовене"],
  },
  {
    id: 4,
    name: "Ирена Илиева",
    otherSpecialist: [],
    phone: "+359887236000",
    helper: [
      {
        id: 103,
        helperName: "Цветан Първанов",
        helperPhone: "+359877185393",
        helperPhoto: Man,
      },
    ],
    otherSpec: null,
    photo: Woman,
    hoursPerDay: [
      "08:30 - 13:30",
      "08:30 - 13:30",
      "08:30 - 13:30",
      "08:30 - 13:30",
      "08:30 - 13:00",
    ],
    workingType: [
      "Амбулаторни прегледи",
      "Домашни прегледи",
      "Промотивна и профилактична дейност",
      "Дейност по детско здравеопазване",
      "Дейност по майчинско здравеопазване",
    ],
  },
  {
    id: 5,
    name: "Стилиян Младенов",
    otherSpecialist: [],
    helper: [
      {
        id: 233,
        helperName: "Георги Цветанов",
        helperPhone: "",
        helperPhoto: Man,
      },
    ],
    phone: "+359888899357",
    otherSpec: null,

    photo: Man,
    hoursPerDay: [" ", "14:00 - 17:00", " ", "14:00 - 17:00", " "],
    workingType: [
      "Амбулаторни прегледи",
      "Домашни прегледи",
      "Промотивна и профилактична дейност",
      "Дейност по детско здравеопазване",
      "Дейност по майчинско здравеопазване",
    ],
    more: ["Извършва прегледи в с. Долно Церовене и с. Дългоделци"],
  },
  {
    id: 6,
    name: "Георги Цветанов",
    mf: "м.ф. Искрен Корчев",
    otherSpecialist: [],
    phone: "+359885320234",
    helper: [
      {
        id: 230,
        helperName: "Марсинела Любомирова",
        helperPhone: "",
        helperPhoto: Woman,
      },
    ],
    otherSpec: null,
    photo: Man,
    hoursPerDay: [
      "08:00 - 10:00 и 13:30 - 16:00",
      "08:00 - 10:00 и 13:30 - 16:00",
      "08:00 - 10:00 и 13:30 - 16:00",
      "08:00 - 10:00 и 13:30 - 16:00",
      "08:00 - 10:00 и 14:30 - 16:00",
    ],
    workingType: [
      "Амбулаторни прегледи",
      "Домашни прегледи",
      "Промотивна и профилактична дейност",
      "Дейност по детско здравеопазване",
      "Дейност по майчинско здравеопазване",
    ],
    more: ["Извършва прегледи в гр. Бойчиновци, с. Септемврийци и с. Мадан"],
  },
];

export default gpData;
