import React, { useState } from "react";
import { List } from "semantic-ui-react";
import ModalWorikingDays from "./working-days-modal.js";
import MyVerticallyCenteredModalSpecalty from "./singe-speciality-modal.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBaby,
  faLungs,
  faFileWord,
  faEye,
  faStethoscope,
  faCrutch,
  faPlus,
  faHeart,
  faBrain,
  faPersonDotsFromLine,
  faXRay,
  faLungsVirus,
  faToilet,
  faPumpMedical,
  faHeadSideMask,
  faBedPulse,
  faDna,
  faPills,
  faHeadSideVirus,
} from "@fortawesome/free-solid-svg-icons";

const getIcon = (name) => {
  switch (name) {
    case "faBedPulse":
      return faBedPulse;
    case "faDna":
      return faDna;
    case "faPills":
      return faPills;
    case "faHeadSideVirus":
      return faHeadSideVirus;
    case "faBaby":
      return faBaby;
    case "faLungs":
      return faLungs;
    case "faFileWord":
      return faFileWord;
    case "faEye":
      return faEye;
    case "faStethoscope":
      return faStethoscope;
    case "faCrutch":
      return faCrutch;
    case "faHeart":
      return faHeart;
    case "faBrain":
      return faBrain;
    case "faPersonDotsFromLine":
      return faPersonDotsFromLine;
    case "faXRay":
      return faXRay;
    case "faLungsVirus":
      return faLungsVirus;
    case "faToilet":
      return faToilet;
    case "faPumpMedical":
      return faPumpMedical;
    case "faHeadSideMask":
      return faHeadSideMask;
    default:
      return faPlus;
  }
};

const SingleSpeciality = ({ el }) => {
  const [modalShow, setModalShow] = useState(false);
  const [modalShowDays, setModalShowDays] = useState(false);

  return (
    <div className="specialty-box">
      <div className="img-holder">
        <FontAwesomeIcon
          className="single-speciality-icon"
          icon={getIcon(el.picture)}
        />
        {/* {el.picture ? <FontAwesomeIcon icon="fa-solid fa-baby" /> : <div>asdasd</div>} */}
      </div>
      <div className="txt-holder">
        <span>
          {" "}
          <span className="name">{el.name}</span>
          {el.preBooking === true ? (
            <span className="text-muted">Платени прегледи</span>
          ) : null}
          {el.hasNZOK === true ? (
            <span className="text-muted">Договор с НЗОК</span>
          ) : null}
          {el.hasButton === true ? (
            <>
              <span
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  setModalShow(true);
                }}
              >
                <br></br>
                <u>
                  <b>Виж специалисти</b>
                </u>
              </span>
              <span
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  setModalShowDays(true);
                  e.preventDefault();
                }}
              >
                <br></br>
                <u>
                  <b>График</b>
                </u>
              </span>
            </>
          ) : null}
        </span>
        <MyVerticallyCenteredModalSpecalty
          show={modalShow}
          onHide={() => setModalShow(false)}
          el={el}
        />
        <ModalWorikingDays
          show={modalShowDays}
          onHide={() => setModalShowDays(false)}
          el={el}
        />
        <br></br>
        <List bulleted>
          {el.info.map((element, index) => {
            return (
              <List.Item key={"listItem_" + index} className="type">
                {element}
              </List.Item>
            );
          })}
        </List>
        <br></br>
      </div>
    </div>
  );
};

export default SingleSpeciality;
