import React from "react";
import pic from "./../../images/ClinicalPsychology/ClinicalPsychology.png";
import "./clinical-psychology.css";

const ClinicalPsychology = (props) => {
  return (
    <div className="main-container" style={{ width: "100%" }}>
      <img alt="pic" style={{ width: "100%" }} src={pic}></img>
      <div className="clinical-psychology_container">
        <div>
          <div className="clinical-psychology_title">Ползи</div>
          <hr></hr>
          <ul>
            <li>Оценка на детското развитие и диагностика</li>
            <li>Превенция на психоемоционалното здраве</li>
            <li>Затруднена адаптация в детската градина/училище</li>
            <li>Поведенчески и емоциаонални проблеми</li>
            <li>Ниска себеоценка</li>
            <li>Страхове,&nbsp; тревожност</li>
            <li>Кошмари</li>
            <li>Раздяла и/или загуба на близък</li>
            <li>Агресивни прояви на опозиционно поведение</li>
            <li>
              Психологическа подкрепа в хода на израстването и изграждането на
              идентичност
            </li>
            <li>
              Подкрепа на деца и юноши със специални образователни потребности
            </li>
            <li>Употреба на ПАВ</li>
          </ul>
        </div>

        <div>
          <div className="clinical-psychology_title">Медицински специалист</div>
          <hr></hr>
          <div className="clinical-psychology_text-bigger">
            <b>Силвия Кроснева</b>
          </div>
          <div className="clinical-psychology_text">
            <b>Контакт:</b>&nbsp;&nbsp;
            <a className="phone-number" href="tel:+359883302770">
              +359883302770
            </a>
          </div>
          <div className="clinical-psychology_text">
            <b>Електронна поща:</b>&nbsp;&nbsp;
            <a href="mailto:krosneva.s@gmail.com">krosneva.s@gmail.com</a>
          </div>
          <div className="clinical-psychology_text">
            <b>Работно време:</b>&nbsp;&nbsp;
            <div className="clinical-psychology_working-time">
              Петък - 10:00 - 17:30 ч.
            </div>
            <div className="clinical-psychology_working-time">
              Събота - 09:30 - 13:30 ч.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClinicalPsychology;
