import React, { useEffect, useState } from "react";
import { Card } from "semantic-ui-react";
import FormControl from "react-bootstrap/FormControl";
import { Typography } from "@mui/material";

import gpData from "../../data/g-p-data.js";

import SingleGP from "../../components/gp-components/g-p-cart/g-p-cart.js";

import LK from "./../../images/LK.svg";

import "./g-p.css";

const Title = ({ text }) => {
  return (
    <>
      <Typography
        className="information"
        style={{ color: "gray", fontSize: "23.5pt", marginTop: "25px" }}
      >
        {" "}
        <b>{text}</b>
      </Typography>
      <hr style={{ marginBottom: "25px" }}></hr>
    </>
  );
};

const GP = () => {
  const [search, setSearch] = useState("");
  const [data, setData] = useState(gpData);

  useEffect(() => {
    if (search.length > 0) {
      const searchedItems = gpData.filter((el) =>
        el.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())
      );
      setData(searchedItems);
    } else {
      setData(gpData);
    }
  }, [search]);

  const allGP = data.map((el, index) => {
    return <SingleGP  el={el} key={"gp_" + index + "_" + el.id} ></SingleGP>;
  });

  return (
    <div className="main-container">
      <img alt="pic" style={{ width: "100%" }} src={LK}></img>
      <Title text="Лични лекари"></Title>
      <div className="search-bar-gp" key="search-bar">
        <FormControl
          type="text"
          placeholder="Търси лекар"
          style={{ height: "50px", fontSize: "1.5rem", marginBottom: "20px" }}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      {allGP.length > 0 ? (
        <Card.Group
          style={{
            margin: "auto",
            padding: "0 1rem",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {allGP}
        </Card.Group>
      ) : (
        <div
          style={{
            margin: "auto",
            justifyContent: "center",
            padding: "0 1rem",
            alignItems: "center",
            display: "flex",
          }}
        >
          <h2>Няма открит лекар.</h2>
        </div>
      )}
    </div>
  );
};

export default GP;
