import React from "react";

import medicalCosmetics1 from "./../../images/MedicalCosmetics/medicalCosmetics1.png";

import "./medicalcosmetics.css";

const MCosmetics = (props) => {
  return (
    <div className="main-container" style={{ width: "100%" }}>
      <img
        alt="medicalCosmetics1"
        style={{ width: "100%" }}
        src={medicalCosmetics1}
      ></img>
      <div className="medical-cosmetics_container">
        <div>
          <div className="medical-cosmetics_title">Услуги</div>
          <hr></hr>
          <ul>
            <li>
              Хиалуронови филъри (устни,&nbsp;нос,&nbsp; скули,&nbsp; брадичка,
              &nbsp;контур)
            </li>
            <li>Заличаване на бръчки с ботокс</li>
            <li>
              Лазер за заличаване на петна, &nbsp;белези,&nbsp;
              татуировки,&nbsp; белези от акне
            </li>
            <li>Скинбустери</li>
            <li>Микропигментация</li>
            <li>Микронидлинг</li>
            <li>Мезотерапия</li>
            <li>Лечение на акне</li>
            <li>Пилинг</li>
          </ul>
        </div>

        <div>
          <div className="medical-cosmetics_title">Медицински специалист</div>
          <hr></hr>
          <div className="medical-cosmetics_text-bigger">
            <b>Лилия-Виктория Петкова</b>
          </div>
          <div className="medical-cosmetics_text">
            <b>Контакт:</b>&nbsp;&nbsp;
            <a className="phone-number" href="tel:+359897533543">
              +359897533543
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MCosmetics;
