import vgeorgiev from "../images/vgeorgiev.jpeg";
import om from "../images/om.jpeg";
import km from "../images/km.jpeg";
import vspasovpic from "../images/vspasov.jpeg";

const guestsData = [
  {
    title: "Д-р Румен Филипов (неврохирург) - 05.12.2024г. от 14:00ч.",
    content: () => {
      return (
        <>
          <h2 className="custom-announcement-title">Д-р Румен Филипов</h2>
          <div className="custom-announcement-container">
            <div className="custom-announcement">
              Д-р Румен Филипов - неврохирург от УМБАЛ "Света Анна" ще извършва
              медицински прегледи на 05.12.2024г. от 14:00ч. <br></br> <br></br>
              Запишете своя час на тел.{" "}
              <a
                className="phone-number"
                style={{
                  color: "rgba(58, 144, 206, 1)",
                  textDecoration: "underline",
                }}
                href="tel:+35996300173"
              >
                096-300-173
              </a>{" "}
              <a
                className="phone-number"
                style={{
                  color: "rgba(58, 144, 206, 1)",
                  textDecoration: "underline",
                }}
                href="tel:+35996300174"
              >
                096-300-174
              </a>{" "}
              или на място в МЦ „Деница“.
            </div>
          </div>
        </>
      );
    },
    date: "12/05/2024",
  },
  {
    title: "Д-р Веселин Георгиев (кардиолог) - 29.05.2024г от 10:30ч",
    content: () => {
      return (
        <>
          <h2 className="custom-announcement-title">
            Д-р Веселин Георгиев (кардиолог)
          </h2>
          <div className="custom-announcement-container">
            <div className="custom-announcement">
              На 29.05.2024г. в МЦ „Деница“ от 10:30ч. ще се проведат
              кардиохирургични консултации с водещият кардиохирург Д-р Веселин
              Георгиев, началник на клиника по кардиохирургия в УМБАЛ „Лозенец“.
              Професионалните интереси на Д-р Георгиев са насочени в областта на
              коронарната и аортна хирургия, митрално-клапно съхраняващи
              операции и миниинвазивната кардиохирургия. <br></br> <br></br>
              Запишете своя час на тел.{" "}
              <a
                className="phone-number"
                style={{
                  color: "rgba(58, 144, 206, 1)",
                  textDecoration: "underline",
                }}
                href="tel:+35996300173"
              >
                096-300-173
              </a>{" "}
              <a
                className="phone-number"
                style={{
                  color: "rgba(58, 144, 206, 1)",
                  textDecoration: "underline",
                }}
                href="tel:+35996300174"
              >
                096-300-174
              </a>{" "}
              или на място в МЦ „Деница“.<br></br> <br></br>
              Желателно е пациентите да представят медицинска документация (ако
              има такава) от предишни кардиологични прегледи.
            </div>
            <img
              loading="lazy"
              src={vgeorgiev}
              alt="dr"
              className="custom-announcement-photo"
            ></img>
          </div>
        </>
      );
    },
    date: "5/29/2024",
  },
  {
    title:
      " Д-р Огнян Матков и Д-р Калоян Матков (обща и съдова хирургия) - 18.12.2024г oт 10:00ч",
    content: () => {
      return (
        <>
          <h2 className="custom-announcement-title">
            Д-р Огнян Матков и Д-р Калоян Матков (Обща и Съдова Хирургия)
          </h2>
          <div className="custom-announcement-container-duo">
            <div className="custom-announcement-duo">
              Д-р Огнян Матков, д.м. е специалист по Обща и Съдова Хирургия с
              повече от 30 години опит в областта на Съдовата Хирургия и
              ангиология. Специализирал в Русия, Италия, Германия и Испания.{" "}
              <br></br>
              <br></br>
              Национален консултант по Съдова хирургия повече от 20 години.
              Автор и съавтор на повече от 80 научни разработки и публикации
              като една трета са във водещи международни издания.
              <br></br>
              <br></br>
              Участвал, извършвал и внедрявал иновативни оперативни техники в
              областта на Микрохирургията и Съдовата хирургия в Република
              България. Богатия и ценен опит е гаранция, че ще получите
              максимално качество и ефективно лечение при съдовите заболявания.
              <br></br>
              <br></br>
              Д-р Огнян Матков, д.м -и д-р Калоян Матков Съдови хирурзи УМБАЛ “
              Света Марина” гр. Плевен - 18.12.2024г
              <br></br>
              <br></br> Телефон:{" "}
              <a
                className="phone-number"
                style={{
                  color: "rgba(58, 144, 206, 1)",
                  textDecoration: "underline",
                }}
                href="tel:+359886849984"
              >
                +359886849984
              </a>{" "}
              <a
                className="phone-number"
                style={{
                  color: "rgba(58, 144, 206, 1)",
                  textDecoration: "underline",
                }}
                href="tel:+35996300173"
              >
                096-300-173
              </a>{" "}
              <a
                className="phone-number"
                style={{
                  color: "rgba(58, 144, 206, 1)",
                  textDecoration: "underline",
                }}
                href="tel:+35996300174"
              >
                096-300-174
              </a>{" "}
              или на място в МЦ „Деница“.
            </div>
            <div className="custom-images-container-duo">
              <img
                loading="lazy"
                src={om}
                alt="dr"
                className="custom-announcement-photo-duo"
              ></img>
              <img
                style={{ marginLeft: "10px" }}
                loading="lazy"
                src={km}
                alt="dr"
                className="custom-announcement-photo-duo"
              ></img>
            </div>
          </div>
        </>
      );
    },
    date: "12/18/2024",
  },
  {
    title: " Д-р Виктор Спасов (ортопед травматолог) - 04.10.2024г oт 14:00ч",
    content: () => {
      return (
        <>
          <h2 className="custom-announcement-title">
            Д-р Виктор Спасов (ортопед травматолог)
          </h2>
          <div className="custom-announcement-container">
            <div className="custom-announcement">
              На 04.10.2024г. в МЦ „Деница“ от 14:00ч. ще се проведат
              консултации с водещия Ортопед травматолог в УМБАЛ "Пирогов" Д-р
              Виктор Спасов. През 2004 г. придобива специалност "Ортопедия и
              травматология".
              <br></br>
              <br></br>
              Притежава сертификати за ендопротезиране на големите стави и
              артроскопия.
              <br></br>
              <br></br>
              AO курсове: Basic (2000 г.), Advanced (2007), Masters - lower
              extremity (2013 г, Швейцария), Pelvis and acetabulum (2014 г.,
              Австрия), Masters - Pelvis and acetabulum with anatomical
              specimens (2015 г., Австрия), Masters – Periprosthetic fractures
              with anatomical specimens (2016 г., Германия).
              <br></br>
              <br></br>
              Клинична специализация в болница „JamesPaget“, Англия (2010 г.)
              <br></br>
              <br></br>С интереси в областта на лечението на пациенти с
              политравма, хирургичното лечение на пациентите с фрактури на таза
              и ацетабулума, първичното и ревизионно ендопротезирането на
              колянната и тазобедрената става.
              <br></br> <br></br>
              Запишете своя час на тел.{" "}
              <a
                className="phone-number"
                style={{
                  color: "rgba(58, 144, 206, 1)",
                  textDecoration: "underline",
                }}
                href="tel:+35996300173"
              >
                096-300-173
              </a>{" "}
              <a
                className="phone-number"
                style={{
                  color: "rgba(58, 144, 206, 1)",
                  textDecoration: "underline",
                }}
                href="tel:+35996300174"
              >
                096-300-174
              </a>{" "}
              или на място в МЦ „Деница“.
            </div>
            <img
              loading="lazy"
              src={vspasovpic}
              alt="dr"
              className="custom-announcement-photo"
            ></img>
          </div>
        </>
      );
    },
    date: "10/4/2024",
  },
];

export default guestsData;
