const specialitiesData = [
  {
    id: 1,
    name: "Акушерство и Гинекология",
    hasNZOK: true,
    preBooking: false,
    picture: "faBaby",
    info: [
      "Ехомамография, Трансвагинална ехография",
      "Ехография щитовидна жлеза, Абдоминална ехография",
      "Наблюдение на бременни",
    ],
    hasButton: true,
    specialists: ["Д-р Таня Джонова", "Д-р Цветомил Мавров- 4D ехография"],
    schedule: [
      [
        "Д-р Таня Джонова",
        "08.00-12.00",
        "08.00-12.00",
        "08.00-12.00",
        "08.00-12.00",
        "08.00-12.00",
      ],
      [
        "Д-р Цветомил Мавров",
        "09.00-12.00",
        "09.00-12.00",
        "09.00-12.00",
        "09.00-12.00",
        "09.00-12.00",
      ],
    ],
  },
  {
    id: 2,
    name: "Вътрешни болести",
    hasNZOK: true,
    preBooking: false,
    picture: "faLungsVirus",
    info: ["УЗД на вътрешни органи", "ЕКГ"],
    hasButton: true,
    specialists: [
      "Д-р Милка Нешева",
      "Д-р Виделинка Цветанова",
      "Д-р Ванко Ванков",
    ],
    schedule: [
      ["Д-р Милка Нешева", "10.00-12.00", "10.00-12.00", "", "10.00-12.00", ""],
    ],
  },
  // {
  //   id: 3,
  //   name: "Ендокринолоия",
  //   hasNZOK: false,
  //   preBooking: true,
  //   picture: "faPills",
  //   info: ["Ехография на щитовидна жлеза"],
  //   hasButton: true,
  //   specialists: ["(Временно не работи)"],
  //   schedule: [],
  // },
  {
    id: 4,
    name: "Ехографски кабинет",
    hasNZOK: false,
    preBooking: false,
    picture: "faXRay",
    info: [],
    hasButton: true,
    specialists: ["Д-р Милка Нешева"],
    schedule: [
      ["Д-р Милка Нешева", "10.00-12.00", "10.00-12.00", "", "10.00-12.00", ""],
    ],
  },
  {
    id: 5,
    name: "Кардиология",
    hasNZOK: true,
    preBooking: false,
    picture: "faHeart",
    info: ["ЕКГ", "Ехокардиография"],
    hasButton: true,
    specialists: ["Д-р Мария-Магдалена Пинтова"],
    schedule: [
      ["Д-р Мария-Магдалена Пинтова", "09.00-16.00", "", "09.00-16.00", "", ""],
    ],
  },
  // {
  //   id: 6,
  //   name: "Кожни и венерически болести",
  //   hasNZOK: true,
  //   preBooking: false,
  //   picture: "faPersonDotsFromLine",
  //   info: ["Криотерапия"],
  //   hasButton: true,
  //   specialists: ["Д-р Николай Данчов (Временно не работи)"],
  //   schedule: [],
  // },
  {
    id: 7,
    name: "Неврология",
    hasNZOK: true,
    preBooking: false,
    picture: "faBrain",
    info: ["ЕЕГ", "Доплерова сонография"],
    hasButton: true,
    specialists: ["Д-р Камелия Стоянова", "Д-р Евгени Милев"],
    schedule: [
      ["Д-р Камелия Стоянова", "", "08.00-13.00", "08.00-13.00", "", ""],
      ["Д-р Евгени Милев", "08.00-13.00", "", "", "", "08.00-13.00"],
    ],
  },
  {
    id: 8,
    name: "Неврохирургия",
    hasNZOK: false,
    preBooking: true,
    picture: "faBrain",
    info: [],
    hasButton: true,
    specialists: ["Д-р Румен Филипов"],
    schedule: [],
  },
  {
    id: 9,
    name: "Нефрология",
    hasNZOK: true,
    preBooking: false,
    picture: "faToilet",
    info: ["Ехография на органите на отделителната система"],
    hasButton: true,
    specialists: [
      "Д-р Михайл Горанов",
      "Д-р Иван Георгиев",
      "Д-р Снежана Йоханова",
    ],
    schedule: [
      [
        "Д-р Михайл Горанов",
        "",
        "08.00-13.00",
        "08.00-13.00",
        "",
        "08.00-13.00",
      ],
      [
        "Д-р Иван Георгиев",
        "08.00-13.00",
        "14.00-17.00",
        "",
        "08.00-13.00",
        "",
      ],
      [
        "Д-р Снежана Йоханова",
        "13.00-16.00",
        "08.00-13.00",
        "08.00-14.00",
        "13.00-16.00",
        "08.00-14.00",
      ],
    ],
  },
  {
    id: 10,
    name: "Медицинска онкология",
    hasNZOK: true,
    preBooking: false,
    picture: "faDna",
    info: [],
    hasButton: true,
    specialists: ["Д-р Радослав Токин"],
    schedule: [
      [
        "Д-р Радослв Токин",
        "14.00-17.00",
        "14.00-17.00",
        "14.00-17.00",
        "14.00-17.00",
        "14.00-17.00",
      ],
    ],
  },
  {
    id: 11,
    name: "Ортопедия и травматология",
    hasNZOK: true,
    preBooking: false,
    picture: "faCrutch",
    info: [],
    hasButton: true,
    specialists: ["Д-р Гошо Славков"],
    schedule: [
      [
        "Д-р Гошо Славков",
        "09.30-12.00",
        "09.30-12.00",
        "09.30-12.00",
        "09.30-12.00",
        "09.30-12.00",
      ],
    ],
  },
  {
    id: 12,
    name: "Очни болести",
    hasNZOK: true,
    preBooking: false,
    picture: "faEye",
    info: ["Периметрия, Въздушна тонометрия", "Авторефактометър, Кератометър"],
    hasButton: true,
    specialists: [
      "Д-р Виолета Гогова",
      "Д-р Милена Средкова - Платени прегледи",
    ],
    schedule: [
      [
        "Д-р Виолета Гогова",
        "08.00-12.00",
        "08.00-12.00",
        "08.00-12.00",
        "08.00-12.00",
        "08.00-12.00",
      ],
    ],
  },
  {
    id: 13,
    name: "Психиатрия, Съдебна психиатрия",
    hasNZOK: true,
    preBooking: false,
    picture: "faHeadSideVirus",
    info: [
      "Психодрама и групова терапия",
      "Консултации на зависими за опиоиди",
      "Сертификат за ръководител на програми за лечение с агонисти и агонист/антигонисти на опиеви рецептори",
      "Специфично лечение на зависимости към амфетамини и метамфетамини",
      "Специфично лечение на алкохолна зависимост",
      "Консултации и лечение на зависими към хазарт",
      "При необходимост консултация с психолог",
    ],
    hasButton: true,
    specialists: ["Д-р Пламен Петров"],
    schedule: [["Д-р Пламен Петров", "", "09.00-12.00", "", "09.00-12.00", ""]],
  },
  {
    id: 14,
    name: "Пневмология и фтизиатрия",
    hasNZOK: true,
    picture: "faLungs",
    info: ["Спирометрия"],
    hasButton: true,
    specialists: ["Д-р Виделинка Цветанова", "Д-р Ванко Ванков"],
    schedule: [
      [
        "Д-р Виделинка Цветанова",
        "13.30-17.30",
        "13.30-17.30",
        "09.00-12:00, 13.30-17.30",
        "13.30-17.30",
        "13.30-17.30",
      ],
      [
        "Д-р Ванко Ванков",
        "",
        "08.00-12.00 16.00-18.00",
        "",
        "",
        "08.00-12.00 16.00-18.00",
      ],
    ],
  },
  {
    id: 15,
    name: "УНГ",
    hasNZOK: true,
    picture: "faHeadSideMask",
    info: ["Аудиометрия"],
    hasButton: true,
    specialists: ["Д-р Димитър Йонов"],
    schedule: [
      [
        "Д-р Димитър Йонов",
        "15.00-17.00",
        "15.00-17.00",
        "15.00-17.00",
        "15.00-17.00",
        "15.00-17.00",
      ],
    ],
  },
  {
    id: 16,
    name: "Урология",
    hasNZOK: true,
    preBooking: false,
    picture: "faToilet",
    info: [],
    hasButton: true,
    specialists: ["Д-р Даниела Василева"],
    schedule: [
      [
        "Д-р Даниела Василева",
        "07.30-.11.30",
        "07.30-.11.30",
        "07.30-.11.30",
        "07.30-.11.30",
        "07.30-.11.30",
      ],
    ],
  },
  {
    id: 17,
    name: "Хирургия",
    hasNZOK: true,
    preBooking: false,
    picture: "faBedPulse",
    info: [],
    hasButton: true,
    specialists: ["Д-р Радослв Токин", "Д-р Сергей Славков"],
    schedule: [
      [
        "Д-р Сергей Славков",
        "10.00-12.00",
        "10.00-12.00",
        "10.00-12.00",
        "10.00-12.00",
        "10.00-12.00",
      ],
      [
        "Д-р Радослв Токин",
        "14.00-17.00",
        "14.00-17.00",
        "14.00-17.00",
        "14.00-17.00",
        "14.00-17.00",
      ],
    ],
  },
  {
    id: 18,
    name: "Съдова хирургия",
    hasNZOK: false,
    preBooking: false,
    picture: "faBedPulse",
    info: [
      "Д-р Огнян Матков, д.м. е специалист по Обща и Съдова Хирургия с повече от 30 години опит в областта на Съдовата Хирургия и ангиология. Специализирал в Русия, Италия, Германия и Испания.",
      "Национален консултант по Съдова хирургия повече от 20 години. Автор и съавтор на повече от 80 научни разработки и публикации като една трета са във водещи международни издания.",
      "Участвал, извършвал и внедрявал иновативни оперативни техники в областта на Микрохирургията и Съдовата хирургия в Република България. Богатия и ценен опит е гаранция, че ще получите максимално качество и ефективно лечение при съдовите заболявания.",
    ],
    hasButton: true,
    specialists: ["Д-р Огнян Матков"],
    schedule: [["Д-р Огнян Матков", "", "", "", "", ""]],
  },
];

export default specialitiesData;
