import React from "react";
import Carousel from "react-bootstrap/Carousel";
import { Grid } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHospital,
  faMedkit,
  faUserNurse,
  faStethoscope,
} from "@fortawesome/free-solid-svg-icons";

import partnersData from "../../data/partners-data.js";
import guestsData from "../../data/guests-data";

import MCView from "../../images/MC1.jpg";
import MCView1 from "../../images/MC2.jpg";
import MCView2 from "../../images/MC3.jpg";

import "./home.css";
import ExpandableList from "../../components/ExpandableList/ExpandableList.js";
import { Button } from "react-bootstrap";

const Home = () => {
  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const openInSameTab = (url) => {
    window.location.href = "/results";
  };

  const allPartners = partnersData.map((el) => {
    return (
      <Grid style={{ cursor: "pointer" }} item key={el.id} xs={3} sm={2} md={2}>
        <img
          className="partner-img"
          alt="pic"
          onClick={(e) => {
            e.preventDefault();
            openInNewTab(el.url);
          }}
          src={el.image}
          herf="www.google.com"
        ></img>
      </Grid>
    );
  });

  function isDateGreaterThanTodayDate(date1) {
    const d1 = new Date(date1);
    const d2 = new Date();

    return d1 > d2;
  }

  return (
    <div className="main-container">
      <Carousel>
        <Carousel.Item interval={2000}>
          <img className="d-block w-100" src={MCView} alt="First slide" />
        </Carousel.Item>
        <Carousel.Item interval={2000}>
          <img className="d-block w-100" src={MCView1} alt="Third slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={MCView2} alt="Third slide" />
        </Carousel.Item>
      </Carousel>
      <div className="home-custom_block">
        <h2 className="home-custom_block-title">Лабораторни резултати</h2>
        <hr></hr>
        <Button className="results-button" size="lg" onClick={openInSameTab}>
          <b>Провери резултатите си тук</b>
        </Button>
        {/* <h2 className="home-custom_block-title">Партньори</h2>
          <hr></hr>
          <Grid className="partners-grid" container spacing={1}>
            {" "}
            {allPartners}
          </Grid> */}
      </div>
      {[
        ...guestsData
          .filter((i) => isDateGreaterThanTodayDate(i?.date))
          .sort((a, b) => new Date(a?.date) - new Date(b?.date)),
      ].length ? (
        <div className="home-custom_block" style={{ marginTop: "15px" }}>
          <h2 className="home-custom_block-title">Гост лекари </h2>
          <hr></hr>
          <ExpandableList
            items={[
              ...guestsData
                .filter((i) => isDateGreaterThanTodayDate(i?.date))
                .sort((a, b) => new Date(a?.date) - new Date(b?.date)),
            ]}
          ></ExpandableList>
        </div>
      ) : (
        <></>
      )}

      <div>
        <p className="medical-center-desc">
          КАБИНЕТИ ЗА СПЕЦИАЛИЗИРАНА МЕДИЦИНСКА ПОМОЩ, МЕДИКО-ДИАГНОСТИЧНА
          ЛАБОРАТОРИЯ, ЛИЧНИ ЛЕКАРИ
        </p>
        <div className="icons-grid">
          <div className="columns">
            <FontAwesomeIcon className="icon" icon={faUserNurse} />
            <p className="icon-title">Специалности</p>
            <p className="icon-text">
              В лечебното заведение работят лекари-специалисти по петнадесет
              специалности. Към него функционира клинична лаборатория за
              извършване на медико-дигностични изследвания.
            </p>
          </div>
          <div className="columns">
            <FontAwesomeIcon className="icon" icon={faHospital} />
            <p className="icon-title">Материална база</p>
            <p className="icon-text">
              Лечебното заведение е открито през 2002г. и разполага със
              съвременна материална база, която непрекъснато се разширява и
              модернизира.
            </p>
          </div>
          <div className="columns">
            <FontAwesomeIcon className="icon" icon={faMedkit} />
            <p className="icon-title">Комплексни услуги</p>
            <p className="icon-text">
              Ние изършваме амбулаторни прегледи, високо специализирани
              дейности, медицински манипулации, домашни прегледи, профилактични
              прегледи, диспансерни прегледи, майчино здравеопазване и др.
            </p>
          </div>
          <div className="columns">
            <FontAwesomeIcon className="icon" icon={faStethoscope} />
            <p className="icon-title">Лични лекари</p>
            <p className="icon-text">
              На територията на лечебното заведение функционират шест медицински
              практики за първична извънболнична медицинска помощ.
            </p>
          </div>
        </div>
        <div className="home-custom_block">
          <h2 className="home-custom_block-title">Партньори</h2>
          <hr></hr>
          <Grid className="partners-grid" container spacing={1}>
            {" "}
            {allPartners}
          </Grid>
        </div>
        <div className="reclame-container">
          <div className="home-custom_block reclame">
            <h2 className="home-custom_block-title">
              Органична козметика за коса{" "}
              <b style={{ fontStyle: "italic" }}>"Healthy Hair Project"</b>
            </h2>
            <hr></hr>
            <a
              href="https://healthyhairprojectt.com"
              style={{ display: "flex" }}
            >
              <img
                className="reclame-photo"
                alt="HHP"
                src="https://healthyhairprojectt.com/assets-be/images/uploaded/32e1874e-2238-4e6a-acac-93474479dc111688379910513132.jpg"
              ></img>
            </a>
          </div>
          <div className="home-custom_block reclame">
            <h2 className="home-custom_block-title">
              <b style={{ fontStyle: "italic" }}>Аптека "Aстра"</b> (гр. Монтана ул. "Цар Борис 3" №18)
            </h2>
            <hr></hr>
            <a href="https://aptekaastra.com" style={{ display: "flex" }}>
              <img
                className="reclame-photo-2"
                alt="HHP"
                style={{ width: "100%", maxWidth: "500px", margin: "auto" }}
                src="https://aptekaastra.com/media/dev/Logo_Astra_.svg"
              ></img>
            </a>
          </div>
          <div className="home-custom_block reclame">
            <h2 className="home-custom_block-title">
              Медицинско облекло<b style={{ fontStyle: "italic" }}>{" "}"Аvramoff"</b>
            </h2>
            <hr></hr>
            <a href="http://medicinskoobleklo-avramoff.com/" style={{ display: "flex" }}>
              <img
                className="reclame-photo-2"
                alt="HHP"
                style={{ width: "100%", maxWidth: "300px", margin: "auto" }}
                src="http://medicinskoobleklo-avramoff.com/images/stories/00_frontpage/logo-malko.png"
              ></img>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
