const specialitiesOthersData = [
  {
    id: 20,
    name: "OБЩА ЛКК",
    hasNZOK: false,
    picture: "faFileWord",
    info: [],
    hasButton: false,
    specialists: [],
  },
  {
    id: 21,
    name: "Специализирана ЛКК АКУШЕРСТВО и ГИНЕКОЛОГИЯ",
    hasNZOK: false,
    picture: "faFileWord",
    info: [],
    hasButton: false,
    specialists: [],
  },
  /*
    { 
        id: 22,
        name: 'Специализирана ЛКК ХИРУРГИЯ',
        hasNZOK: false,
        preBooking: false,
        picture: NoteAndPen,
        info: [],
        hasButton: false,
        specialists: []
    },
  */
  {
    id: 23,
    name: "Издаване на медицински",
    picture: "faFileWord",
    hasNZOK: false,
    preBooking: false,
    info: [
      "Шофьор, Работа, Брак",
      "Работа в специализирана институция,",
      "Оръжие, Студент, Осиновяване",
    ],
    hasButton: false,
    specialists: [],
  },
  {
    id: 24,
    name: "Медицински удостоверения",
    hasNZOK: false,
    picture: "faFileWord",
    preBooking: false,
    info: [
      "Протоколи за ТЕЛК, Бюро по труда, Cоциални грижи",
      "Психиатрично удостоверение, Етапна епикриза",
      "Медицинска характеристика за постъпване в социален дом",
    ],
    hasButton: false,
    specialists: [],
  },
];

export default specialitiesOthersData;