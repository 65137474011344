import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import FormControl from "react-bootstrap/FormControl";
import SingleSpecialty from "../../components/specialties-components/singe-speciality.js";

import specialitiesData from "../../data/specialities-data";
import specialitiesOthersData from "../../data/specialities-others-data";

import SpecaltyPhoto from "./../../images/Speciality/page/MC.PNG";

import "./specialities.css";

const Title = ({ text }) => {
  return (
    <>
      <Typography
        className="information"
        style={{ color: "gray", fontSize: "19.5pt", marginTop: "25px" }}
      >
        {" "}
        <b>{text}</b>
      </Typography>
      <hr style={{ marginBottom: "25px" }}></hr>
    </>
  );
};

const Specialties = (props) => {
  const [search, setSearch] = useState("");
  const [data, setData] = useState(specialitiesData);

  useEffect(() => {
    if (search.length > 0) {
      const searchedItems = specialitiesData.filter((el) =>
        el.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())
      );
      setData(searchedItems);
    } else {
      setData(specialitiesData);
    }
  }, [search]);

  const allOthersSpeciality = specialitiesOthersData.map((el, index) => {
    return <SingleSpecialty key={el.id + "" + index} el={el}></SingleSpecialty>;
  });

  const AllSpecialty = data.map((el) => {
    return <SingleSpecialty key={el.id} el={el}></SingleSpecialty>;
  });

  return (
    <div className="main-container">
      <img alt="pic" className="specalty-photo" src={SpecaltyPhoto}></img>
      <div className="col-fixed-big">
        <Title text="Специалности"></Title>
        <div className="search-bar" key="search-bar">
          <FormControl
            type="text"
            placeholder="Търси специалност"
            style={{ height: "50px", fontSize: "1.5rem", marginBottom: "25px" }}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        {AllSpecialty.length > 0 ? (
          <div className="in-tabs-descr">{AllSpecialty}</div>
        ) : (
          <Typography
            className="information"
            style={{
              fontSize: "19.5pt",
              marginTop: "5%",
              marginBottom: "20px",
            }}
          >
            <b>Няма открити специалности.</b>
          </Typography>
        )}
      </div>
      <div className="col-fixed">
        <Title text="Лекарски Консултативни Комисии"></Title>
        {allOthersSpeciality}
      </div>
    </div>
  );
};

export default Specialties;
