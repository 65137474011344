import React, { useState, useRef, useEffect } from "react";
import "./expandableLists.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";


const ExpandableList = ({ items }) => {
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [headerHeight, setHeaderHeight] = useState(0);

  useEffect(() => {
    const header = document.querySelector(".navbar");
    if (header) {
      setHeaderHeight(header.getBoundingClientRect().height);
    }
  }, []);

  const itemRefs = useRef([]);

  const handleClick = (index) => {
    const element = itemRefs.current[index];
    // const elementHeight = element.getBoundingClientRect().height;

    setExpandedIndex(index === expandedIndex ? null : index);

    if (index !== expandedIndex) {
      setTimeout(() => {
        const offsetTop =
          element.getBoundingClientRect().top + window.pageYOffset;
        window.scrollTo({
          top: offsetTop - headerHeight - 50,
          behavior: "smooth",
        });
      }, 100);
    }
  };

  const sortedItems = items;

  return (
    <div className="expandable-list">
      {sortedItems.map((item, index) => (
        <div
          key={index}
          className={`list-item ${expandedIndex === index ? "list-item-selected" : ""}`}
          ref={(el) => (itemRefs.current[index] = el)}
        >
          <div className={`list-header ${expandedIndex === index ? "list-header-selected" : ""}`} onClick={() => handleClick(index)}>
            <div>
              <b>{item.title}</b>
            </div>
            <FontAwesomeIcon
              icon={expandedIndex === index ? faChevronUp : faChevronDown}
              className="chevron-icon"
            />
          </div>

          {expandedIndex === index && (
            <div className="list-content">
              <item.content></item.content>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default ExpandableList;
