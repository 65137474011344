import React from "react";

const LaboratoryTableElement = (props) => {
  const el = props.el;
  const title = (
    <tr>
      <th>
        {el.id}. {el.type}
      </th>
    </tr>
  );

  const info = el.info.map((elInfo, index) => {
    const text = elInfo.text.map((elText, index) => {
      return (
        <tr key={elText + "_" + index}>
          <td>&nbsp; &nbsp;&nbsp; &nbsp; - {elText}</td>
        </tr>
      );
    });

    return (
      <React.Fragment key={`table_text_${index}`}>
        {elInfo.subType ? (
          <tr>
            <th> &nbsp; &nbsp; • &nbsp; {elInfo.subType}</th>
          </tr>
        ) : null}
        {text}
      </React.Fragment>
    );
  });

  return (
    <React.Fragment>
      {title}
      {info}
    </React.Fragment>
  );
};

export default LaboratoryTableElement;
