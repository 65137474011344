import React from "react";
import "./footer.css";

const PhoneNumber = ({ number }) => {
  const href = `tel:${number}`;
  return (
    <div className="footer-title">
      <a className="phone-number" href={href}>
        {number}
      </a>
    </div>
  );
};

const FooterTitle = ({ text }) => {
  return (
    <div>
      <h4 className="footer-title">
        <b>{text}</b>
      </h4>
    </div>
  );
};

const Footer = () => {
  return (
    <footer className="footer-box">
      <div className="footer">
        <div className="row">
          {/* Column1 */}
          <div className="col">
            <FooterTitle text="Контакти Регистратура"></FooterTitle>
            <PhoneNumber number={"+35996300173"}></PhoneNumber>
            <PhoneNumber number={"+35996300174"}></PhoneNumber>
            <br></br>
            <FooterTitle text="Контакти Лаборатория"></FooterTitle>
            <PhoneNumber number={"+35996300165"}></PhoneNumber>
          </div>
          {/* Column2 */}
          <div className="col">
            <FooterTitle text="Адрес"></FooterTitle>
            <div className="footer-text">
              <b>пл. Алеко Константинов ет.2 и ет.3</b>
              <br></br>
              3400 Монтана Център
              <br></br>
              над магазин ГУМ
            </div>
            <br></br>
            <FooterTitle text="E-mail"></FooterTitle>
            <div className="footer-title">
              <a href="mailto:mc_denica@mail.bg">mc_denica@mail.bg</a>
            </div>
            <br></br>
          </div>
          {/* Column3 */}
          <div className="col">
            <FooterTitle text="Работно време"></FooterTitle>
            <div style={{ marginTop: "5px" }}>
              <h6 className="footer-text">
                <b>Понеделник - Петък</b>
                <br></br>
                07:30 - 18:00
              </h6>
            </div>
            <div style={{ marginTop: "8px" }}>
              <h6 className="footer-text">
                <b>
                  Събота<br></br>(При предварителни записвания)
                </b>
                <br></br>09:00 - 12:00
              </h6>
            </div>
          </div>
        </div>
      </div>
      <div className="gmap_canvas">
        <h2 className="footer-title">
          <b>Карта</b>
        </h2>
        <iframe
          title="map"
          className="frame"
          id="gmap_canvas"
          src="https://maps.google.com/maps?q=%D0%BC%D0%B5%D0%B4%D0%B8%D1%86%D0%B8%D0%BD%D1%81%D0%BA%D0%B8%20%D1%86%D0%B5%D0%BD%D1%82%D1%8A%D1%80%20%D0%B4%D0%B5%D0%BD%D0%B8%D1%86%D0%B0&t=&z=15&ie=UTF8&iwloc=&output=embed"
          frameBorder={0}
          scrolling="no"
          marginHeight={0}
          marginWidth={0}
        />
      </div>
    </footer>
  );
};

export default Footer;
