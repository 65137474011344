import React, { useEffect, useState } from "react";

const Resuts = () => {
  const [reload, setReload] = useState(false);
  useEffect(() => {
    const currentDate = new Date();
    const currentHour = currentDate.getHours();
    const currentDay = currentDate.getDay();

    if (
      currentHour >= 7 &&
      currentHour < 19 &&
      currentDay >= 1 &&
      currentDay <= 5
    ) {
      window.location.replace("https://rezultati.mcdenica.com:9090");
    } else {
      setReload(true);
    }
  }, []);

  return (
    <div style={{ paddingTop: "100px", height: "500px", textAlign: "center", marginLeft: "50px", marginRight: "50px" }}>
      {reload ? (
        <h1 style={{ color: "gray", margin: "auto" }}>
          Можете да проверявате резултатите от понеделник до петък, в интервала
          от 07:00 до 18:00 часа.
        </h1>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Resuts;
