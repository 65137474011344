import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import logo from "./../../images/BothLogos1.svg";
import "./header.css";
// import NavDropdown from 'react-bootstrap/NavDropdown';

const Header = () => {
  return (
    <Navbar
      collapseOnSelect
      className="navbar-custom"
      expand="lg"
      variant="dark"
    >
      <Navbar.Brand href="/">
        <img
          src={logo}
          className="d-inline-block align-top"
          alt="Logo"
          style={{
            width: "280px",
            height: "30px",
          }}
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto" />
        <Nav
          style={{ float: "right", textAlign: "right" }}
          className="mr-right"
        >
          <Nav.Link href="/">
            <b className="header-link">Начало</b>
          </Nav.Link>
          <Nav.Link href="specialities">
            <b className="header-link">Специалности</b>
          </Nav.Link>
          <Nav.Link href="doctors">
            <b className="header-link">Лични лекари</b>
          </Nav.Link>
          <Nav.Link href="laboratory">
            <b className="header-link">Лаборатория</b>
          </Nav.Link>
          <Nav.Link href="clinical-psychology">
            <b className="header-link">Клинична Психология</b>
          </Nav.Link>
          <Nav.Link href="medical-cosmetics">
            <b className="header-link">Медицинска козметика</b>
          </Nav.Link>
          <Nav.Link href="results">
            <b className="header-link">Лабораторни резултати</b>
          </Nav.Link>
          {/* <NavDropdown className="header-link" title="Други" id="navbarScrollingDropdown" style={{fontWeight:"bold"}}>
              <NavDropdown.Item href="clinical-psychology">Клинична Психология</NavDropdown.Item>
              <NavDropdown.Item href="medical-cosmetics">
              Медицинска козметика
              </NavDropdown.Item>
            </NavDropdown> */}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
